import { MachineProps, MachineState, MachineStateColorStyle, PluginData } from '@types';
import { sanitizeMachineId } from './enk-utils';
import { ThemeVizColor } from '@grafana/data';
import * as df from 'd-forest';
import { ColorTranslator, HSLObject } from 'colortranslator';

/* eslint-disable no-template-curly-in-string */
export function constructMachineVariable(enkName: string): string {
  const enkParts = enkName.split('/');
  const constructedMachineVariable = `${enkParts[enkParts.length - 3]}.${enkParts[enkParts.length - 2]}`;
  return constructedMachineVariable;
}

export function interpolateMachineVariable(machineLink: string, enkName: string): string {
  if (machineLink.includes('${__machine}')) {
    return machineLink.replace('${__machine}', enkName);
  }

  if (machineLink.includes('${__machine:id}')) {
    return machineLink.replace('${__machine:id}', enkName);
  }

  if (machineLink.includes('${__machine:displayname}')) {
    return machineLink.replace('${__machine:displayname}', constructMachineVariable(enkName));
  }

  if (machineLink.includes('${__machine:displayName}')) {
    return machineLink.replace('${__machine:displayName}', constructMachineVariable(enkName));
  }

  return machineLink;
}

export function getMachineState(pluginPanelData: PluginData, props: MachineProps): MachineState | undefined {
  return pluginPanelData.lineData?.machineState.find(
    (machineState) => sanitizeMachineId(machineState.enkName) === sanitizeMachineId(props.machine.enkName)
  );
}

export function setStrokeColor(hslColor: HSLObject) {
  return hslColor;
}

export function setMachineStateColor(
  pluginPanelData: PluginData,
  machineProps: MachineProps,
  theme: any
): MachineStateColorStyle {
  const machineState = getMachineState(pluginPanelData, machineProps);
  const hasGrafanaTheme = Object.keys(theme).length > 0;

  if (machineState && machineState.state.state && pluginPanelData.options) {
    const color = pluginPanelData.options[machineState.state.state];
    let themeColor;
    if (hasGrafanaTheme) {
      themeColor = df.findLeaf<ThemeVizColor>(theme.visualization.hues, (node) => node.name === color);
    }

    const colorTranslator = new ColorTranslator(themeColor ? themeColor.color : color);
    const hslColor = colorTranslator.HSLObject;

    const strokeColor = setStrokeColor(hslColor);
    const machineStateColorStyle = {
      fill: `hsl(${hslColor.h.toFixed(0)}, ${hslColor.s.toFixed(0)}%, ${hslColor.l.toFixed(0)}%, 1)`,
      stroke: `hsl(${strokeColor.h.toFixed(0)}, ${strokeColor.s.toFixed(0)}%, ${strokeColor.l.toFixed(0)}%, 0.4)`,
      strokeWidth: '3px',
    };
    return machineStateColorStyle;
  }

  return {
    fill: `hsl(0, 0%, 69%, 0.8)`,
    stroke: `hsl(0, 0%, 69%, 1)`,
    strokeWidth: '3px',
  };
}

const oneHour = 3600;
const oneDay = 86400;
const oneMinute = 60;

function getSeconds(durationInSeconds: number): number {
  return durationInSeconds % oneMinute;
}

function getMinutes(durationInSeconds: number): number {
  return Math.floor((durationInSeconds / oneMinute) % oneMinute);
}

function getHours(durationInSeconds: number): number {
  return Math.floor((durationInSeconds / oneHour) % 24);
}

function getDays(durationInSeconds: number): number {
  return Math.floor(durationInSeconds / oneDay);
}

function formatAsMinutesSeconds(durationInSeconds: number): string {
  return `${getMinutes(durationInSeconds).toString().padStart(2, '0')}:${getSeconds(durationInSeconds)
    .toString()
    .padStart(2, '0')}`;
}

function formatAsHoursMinutesSeconds(durationInSeconds: number): string {
  return `${getHours(durationInSeconds).toString().padStart(2, '0')}:${getMinutes(durationInSeconds)
    .toString()
    .padStart(2, '0')}:${getSeconds(durationInSeconds).toString().padStart(2, '0')}`;
}

function formatAsDaysHours(durationInSeconds: number): string {
  return `${getDays(durationInSeconds).toString().padStart(2, '0')}d ${getHours(durationInSeconds)
    .toString()
    .padStart(2, '0')}`;
}

export function formatDuration(timestamp: number | undefined): string {
  let returnString = '-:-';

  if (timestamp) {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const durationInSeconds = currentTimeInSeconds - Math.floor(timestamp / 1000);

    if (durationInSeconds < oneHour) {
      returnString = formatAsMinutesSeconds(durationInSeconds);
    }

    if (durationInSeconds >= oneHour && durationInSeconds < oneHour * 100) {
      returnString = formatAsHoursMinutesSeconds(durationInSeconds);
    }

    if (durationInSeconds >= oneHour * 100) {
      returnString = formatAsDaysHours(durationInSeconds);
    }
  }

  return returnString;
}
