import { Line, MachineGroupProps } from '@types';
import { Node } from 'reactflow';

export function sanitizeMachineId(enkName: string) {
  return enkName.endsWith('/') ? enkName : `${enkName}/`;
}

function updateEnkNamesInNodeArray(nodes: Node<MachineGroupProps>[]) {
  nodes.forEach((node) => {
    node.data.machineGroup.components.forEach((component) => {
      component.enkName = sanitizeMachineId(component.enkName);
    });
  });
}

export function verifyEnkNames(updatedLine: Line): Line {
  updateEnkNamesInNodeArray(updatedLine.nodes);
  updatedLine.views.forEach((view) => {
    updateEnkNamesInNodeArray(view.nodes);
  });
  return updatedLine;
}
