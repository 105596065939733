import { createSlice } from '@reduxjs/toolkit';
import { ApiLineResponse, Component, Line, SelectedElement } from '@types';
import { Edge, Node } from 'reactflow';
import * as _ from 'lodash';
import {
  createLine,
  createView,
  deleteLine,
  deleteView,
  fetchEnkNames,
  fetchLines,
  saveLine,
  saveView,
} from './lineActions';

export interface LinesReducerState {
  lines: Line[];
  activeLineId: SelectedElement;
  activeLineIndex: number | undefined;
  selectedNodeId: SelectedElement;
  selectedNodeType: SelectedElement;
  visibilityArray: string[];
  viewTitle: string;
  hideNodes: boolean;
  activeViewIndex: number;
  activeViewId: string;
  enkNamesInDB: string[];
  showSnackbar: boolean;
  snackbarMessage: string;
}

const initialLinesState: LinesReducerState = {
  lines: [],
  activeLineId: undefined,
  activeLineIndex: undefined,
  selectedNodeId: undefined,
  selectedNodeType: undefined,
  visibilityArray: [],
  viewTitle: '',
  hideNodes: false,
  activeViewIndex: 0,
  activeViewId: '',
  enkNamesInDB: [],
  showSnackbar: false,
  snackbarMessage: '',
};

const linesSlice = createSlice({
  name: 'lines',
  initialState: initialLinesState,
  reducers: {
    updateLine(state, action: { payload: { updatedDetails: Line } }) {
      const updatedLineDetails = action.payload.updatedDetails;
      const lineIndex = state.lines.findIndex((line) => line.id === updatedLineDetails.id);

      if (lineIndex > -1) {
        const allLines = _.cloneDeep(state.lines) as Line[];
        allLines[lineIndex] = {
          ...allLines[lineIndex],
          displayName: updatedLineDetails.displayName,
          domain: updatedLineDetails.domain,
          factory: updatedLineDetails.factory,
          type: updatedLineDetails.type,
        };
        state.lines = allLines;
      }
    },

    setLines(state, action) {
      state.lines = action.payload;
    },

    setLine(state, action: { payload: Line }) {
      const updatedLines = _.cloneDeep(state.lines);
      const lineIndex = state.lines.findIndex((line) => line.id === state.activeLineId);
      if (lineIndex > -1) {
        updatedLines[lineIndex] = action.payload;
        state.lines = updatedLines;
      }
    },

    setUpdatedNodes(state, action: { payload: Node[] }) {
      const lineIndex = state.lines.findIndex((line) => line.id === state.activeLineId);
      const updatedLines = _.cloneDeep(state.lines) as Line[];
      updatedLines[lineIndex].nodes = action.payload;
      updatedLines[lineIndex].views[state.activeViewIndex].nodes = action.payload;
      state.lines = updatedLines;
    },

    setUpdatedEdges(state, action: { payload: Edge[] }) {
      const lineIndex = state.lines.findIndex((line) => line.id === state.activeLineId);
      const updatedLines = _.cloneDeep(state.lines) as Line[];
      updatedLines[lineIndex].edges = action.payload;
      updatedLines[lineIndex].views[state.activeViewIndex].edges = action.payload;
      state.lines = updatedLines;
    },

    setActiveLineId(state, action: { payload: SelectedElement }) {
      state.activeLineId = action.payload;
    },

    setSelectedNodeType(state, action: { payload: SelectedElement }) {
      state.selectedNodeType = action.payload;
    },

    setActiveViewId(state, action: { payload: string }) {
      state.activeViewId = action.payload;
    },

    setActiveViewIndex(state, action: { payload: number }) {
      state.activeViewIndex = action.payload;
    },

    setSelectedGroupId(state, action: { payload: SelectedElement }) {
      state.selectedNodeId = action.payload;

      const line = state.lines.find((line) => line.id === state.activeLineId);
      const lineIndex = state.lines.findIndex((line) => line.id === state.activeLineId);
      if (line) {
        const newLine = _.cloneDeep(line) as Line;
        newLine.nodes.forEach((node: Node) => {
          if (node.id === action.payload) {
            node.selected = true;
          } else {
            node.selected = false;
          }
        });
        state.lines[lineIndex] = newLine;
      }
    },

    setViewTitle(state, action) {
      state.viewTitle = action.payload;
    },

    deselectAllNodesInView(state) {
      state.visibilityArray = [];
    },

    selectAllNodesInView(state) {
      const line = state.lines.find((line) => line.id === state.activeLineId);
      const visibleNodeIds: string[] = [];
      if (line) {
        line.views[state.activeViewIndex].nodes.forEach((node) => visibleNodeIds.push(node.id));
      }
      state.visibilityArray = visibleNodeIds;
    },

    updateGroupVisibilityArray(state, action) {
      const index = state.visibilityArray.indexOf(action.payload);

      if (index === -1) {
        const newVisibilityArray = [...state.visibilityArray];
        newVisibilityArray.push(action.payload);
        newVisibilityArray.sort();
        state.visibilityArray = newVisibilityArray;
      }

      if (index > -1) {
        const newVisibilityArray = [...state.visibilityArray];
        newVisibilityArray.splice(index, 1);
        newVisibilityArray.sort();
        state.visibilityArray = newVisibilityArray;
      }
    },

    setLineView(state, action: { payload: { viewId: string } }) {
      const line = _.cloneDeep(state.lines.find((line) => line.id === state.activeLineId)) as Line;
      const lineIndex = state.lines.findIndex((line) => line.id === state.activeLineId);

      if (line) {
        const selectedView = line.views.find((view) => view.viewId === action.payload.viewId);
        const viewIndex = line.views.findIndex((view) => view.viewId === action.payload.viewId);
        if (selectedView && lineIndex > -1 && viewIndex > -1) {
          const updatedVisibilityArray = selectedView.nodes.map((node) => node.id);

          state.visibilityArray = updatedVisibilityArray;
          state.lines[lineIndex].nodes = selectedView.nodes;
          state.lines[lineIndex].edges = selectedView.edges;
          state.lines[lineIndex].viewport = selectedView.viewport;
          state.viewTitle = selectedView.viewTitle;
          state.activeViewIndex = viewIndex;
          state.activeViewId = selectedView.viewId;
        }
      }
    },

    setNIOCounter(state, action: { payload: { id: string; isChecked: boolean } }) {
      const lines = _.cloneDeep(state.lines);
      const lineIndex = state.lines.findIndex((line) => line.id === state.activeLineId);

      const activeLine = lines.find((line) => line.id === state.activeLineId);
      if (activeLine) {
        const updatedLine = _.cloneDeep(activeLine);

        updatedLine.nodes.forEach((node: Node) => {
          node.data.machineGroup.components.forEach((component: Component) => {
            component.isNIOCounter = false;
            if (component.enkName === action.payload.id) {
              component.isNIOCounter = action.payload.isChecked;
            }
          });
        });

        updatedLine.views[state.activeViewIndex].nodes.forEach((node: Node) => {
          node.data.machineGroup.components.forEach((component: Component) => {
            component.isNIOCounter = false;
            if (component.enkName === action.payload.id) {
              component.isNIOCounter = action.payload.isChecked;
            }
          });
        });

        lines[lineIndex] = updatedLine;
        state.lines = lines;
      }
    },

    setIOCounter(state, action: { payload: { id: string; isChecked: boolean } }) {
      const lines = _.cloneDeep(state.lines) as Line[];
      const lineIndex = state.lines.findIndex((line) => line.id === state.activeLineId);

      const activeLine = lines.find((line) => line.id === state.activeLineId);
      if (activeLine) {
        const updatedLine = { ...activeLine };

        updatedLine.nodes.forEach((node: Node) => {
          node.data.machineGroup.components.forEach((component: Component) => {
            component.isIOCounter = false;
            if (component.enkName === action.payload.id) {
              component.isIOCounter = action.payload.isChecked;
            }
          });
        });

        updatedLine.views[state.activeViewIndex].nodes.forEach((node: Node) => {
          node.data.machineGroup.components.forEach((component: Component) => {
            component.isIOCounter = false;
            if (component.enkName === action.payload.id) {
              component.isIOCounter = action.payload.isChecked;
            }
          });
        });

        lines[lineIndex] = updatedLine;
        state.lines = lines;
      }
    },

    setEnkMatching(state, action: { payload: { enkNames: string[] } }) {
      state.enkNamesInDB = action.payload.enkNames;
    },

    setShowSnackbar(state, action: { payload: boolean }) {
      state.showSnackbar = action.payload;
    },

    setSnackbarMessage(state, action: { payload: string }) {
      state.snackbarMessage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchLines.fulfilled, (state, action: { payload: Line[] | void }) => {
        if (action.payload) {
          state.lines = action.payload;
        }
      })

      .addCase(fetchLines.rejected, (state) => {
        state.snackbarMessage = 'Linien konnten nicht geladen werden';
        state.showSnackbar = true;
      })

      .addCase(fetchEnkNames.fulfilled, (state, action: { payload: string[] | void }) => {
        if (action.payload) {
          state.enkNamesInDB = action.payload;
        }
      })

      .addCase(createLine.fulfilled, (state, action: { payload: ApiLineResponse | undefined }) => {
        if (action.payload) {
          state.lines.push(action.payload.line_data);
          state.activeLineId = action.payload.line_data.id;
          state.snackbarMessage = 'Linie erstellt';
          state.showSnackbar = true;
        }
      })

      .addCase(createLine.rejected, (state) => {
        state.snackbarMessage = 'Erstellen der Linie fehlgeschlagen';
        state.showSnackbar = true;
        state.activeLineId = undefined;
      })

      .addCase(saveLine.fulfilled, (state, action: { payload: Line[] | void }) => {
        if (action.payload) {
          state.lines = action.payload;
          state.snackbarMessage = 'Speichern erfolgreich';
          state.showSnackbar = true;
        }
      })
      .addCase(saveLine.rejected, (state) => {
        state.snackbarMessage = 'Speichern fehlgeschlagen';
        state.showSnackbar = true;
      })

      .addCase(deleteLine.fulfilled, (state, action: { payload: Line[] | void }) => {
        if (action.payload) {
          state.lines = action.payload;
          state.snackbarMessage = 'Linie erfolgreich gelöscht';
          state.showSnackbar = true;
        }
      })

      .addCase(deleteLine.rejected, (state) => {
        state.snackbarMessage = 'Löschen fehlgeschlagen';
        state.showSnackbar = true;
      })

      .addCase(saveView.fulfilled, (state, action: { payload: Line[] | void }) => {
        if (action.payload) {
          state.lines = action.payload;
          state.snackbarMessage = 'Ansicht gespeichert';
          state.showSnackbar = true;
        }
      })

      .addCase(saveView.rejected, (state) => {
        state.snackbarMessage = 'Fehler beim Speichern der Ansicht';
        state.showSnackbar = true;
      })

      .addCase(
        createView.fulfilled,
        (
          state,
          action: {
            payload: {
              lines: Line[];
              viewIndex: number;
              visibilityArray: string[];
              viewId: string;
            } | void;
          }
        ) => {
          if (action.payload) {
            state.lines = action.payload.lines;
            state.activeViewId = action.payload.viewId;
            state.activeViewIndex = action.payload.viewIndex;
            state.visibilityArray = action.payload.visibilityArray;
            state.snackbarMessage = 'Ansicht erzeugt';
            state.showSnackbar = true;
          }
        }
      )

      .addCase(createView.rejected, (state) => {
        state.snackbarMessage = 'Fehler beim Erstellen der Ansicht';
        state.showSnackbar = true;
      })

      .addCase(deleteView.fulfilled, (state, action: { payload: Line[] | undefined }) => {
        if (action.payload) {
          state.activeViewIndex = 0;
          state.snackbarMessage = 'Ansicht gelöscht';
          state.showSnackbar = true;
          state.lines = action.payload;
        }
      })

      .addCase(deleteView.rejected, (state) => {
        state.snackbarMessage = 'Fehler beim Löschen der Ansicht';
        state.showSnackbar = true;
      });
  },
});

export const lineActions = linesSlice.actions;
export const linesReducer = linesSlice.reducer;
