import { ApiLineResponse, Line, MachineGroupProps, View } from '@types';
import { RootState } from './store';
import { environment } from '@environments';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { Edge, Node, ReactFlowJsonObject } from 'reactflow';
import { setMachineMultiplier, setNewViewId, verifyEnkNames } from '@utils';

export const fetchLines = createAsyncThunk('lines/fetchLines', async (data, thunkApi) => {
  try {
    const response = await axios.get(`${environment.host_url}/api/line`);
    if (response.status === 200) {
      const linesData: ApiLineResponse[] = response.data;
      if (linesData && linesData.length > 0) {
        const parsedLinesData: Line[] = linesData.map((line) => line.line_data);
        return parsedLinesData;
      }
    }
    return;
  } catch (error) {
    console.error('Error fetching line data', error);
    thunkApi.abort();
    return;
  }
});

export const fetchEnkNames = createAsyncThunk('lines/fetchEnkNames', async (data, thunkApi) => {
  try {
    const response = await axios.get(`${environment.host_url}/api/enkNames`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching enk names', error);
  }
});

export const saveLine = createAsyncThunk(
  'lines/saveLine',
  async (data: { line: Line; saveToServer: boolean }, thunkApi) => {
    const { line: updatedLine, saveToServer } = data;
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const allLines = _.cloneDeep(state.lines.lines);

    const lineIndex = allLines.findIndex((line) => line.id === updatedLine.id);
    if (lineIndex > -1) {
      const newUpdatedLine = _.cloneDeep(updatedLine);
      setMachineMultiplier(newUpdatedLine);
      const verifiedLine = verifyEnkNames(newUpdatedLine);

      allLines[lineIndex] = verifiedLine;
      const views = [...allLines[lineIndex].views];
      const activeViewIndex = state.lines.activeViewIndex;

      views[activeViewIndex] = {
        viewId: `${allLines[lineIndex].id}-${activeViewIndex}`,
        viewTitle: allLines[lineIndex].views[activeViewIndex].viewTitle,
        nodes: allLines[lineIndex].nodes,
        edges: allLines[lineIndex].edges,
        viewport: allLines[lineIndex].viewport,
      };

      allLines[lineIndex].views = views;

      if (saveToServer) {
        try {
          const response = await axios.post(`${environment.host_url}/api/line`, { line: verifiedLine });
          if (response.status === 200) {
            return allLines;
          }
        } catch (error) {
          console.error('Error saving line', error);
          thunkApi.abort();
          return;
        }
      }
    }
    return;
  }
);

export const createLine = createAsyncThunk('lines/createLine', async (line: Line, thunkApi) => {
  try {
    const response = await axios.post(`${environment.host_url}/api/line`, { line });
    if (response.status === 200) {
      return response.data as ApiLineResponse;
    }
    return;
  } catch (error) {
    console.error('Error creating line', error);
    thunkApi.abort();
    return;
  }
});

export const deleteLine = createAsyncThunk('lines/deleteLine', async (data, thunkApi) => {
  const { getState } = thunkApi;
  const rootState = getState() as RootState;
  const state = rootState.lines;

  const index = state.lines.findIndex((line) => line.id === state.activeLineId);
  if (index > -1) {
    const lines = _.cloneDeep(state.lines);
    try {
      const response = await axios.delete(`${environment.host_url}/api/line/${state.activeLineId}`);
      if (response.status === 204) {
        lines.splice(index, 1);
        return lines;
      }
    } catch (error) {
      console.error('Error deleting line', error);
      thunkApi.abort();
      return;
    }
  }
  return;
});

export const createView = createAsyncThunk('lines/createView', async (data, thunkApi) => {
  const { getState } = thunkApi;
  const rootState = getState() as RootState;
  const state = rootState.lines;

  const lines = _.cloneDeep(state.lines);
  const line = lines.find((line) => line.id === state.activeLineId);
  const lineIndex = lines.findIndex((line) => line.id === state.activeLineId);

  if (line && lineIndex !== -1) {
    const newViewIndex = setNewViewId(line.views);
    const viewId = `${line.id}-${newViewIndex}`;
    const adaptedLine = _.cloneDeep(line);

    const newView: View = {
      viewId: viewId,
      viewTitle: 'Neues Board',
      nodes: adaptedLine.views[0].nodes,
      edges: adaptedLine.views[0].edges,
    };
    adaptedLine.views.push(newView);
    const activeViewIndex = adaptedLine.views.length - 1;
    lines[lineIndex] = adaptedLine;

    const visibilityArray = line.nodes.map((node) => node.id);
    try {
      const response = await axios.post(`${environment.host_url}/api/line`, { line: adaptedLine });
      if (response.status === 200) {
        return { lines: lines, viewIndex: activeViewIndex, visibilityArray: visibilityArray, viewId: viewId };
      }
    } catch (error) {
      console.error('Error creating view', error);
      thunkApi.abort();
      return;
    }
  }
  return;
});

export const saveView = createAsyncThunk(
  'lines/saveView',
  async (
    data: {
      viewIndex: number;
      lineData: ReactFlowJsonObject;
    },
    thunkApi
  ) => {
    const { getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.lines;
    const { viewIndex, lineData } = data;

    const lines = _.cloneDeep(state.lines);
    const line = lines.find((line) => line.id === state.activeLineId);
    const lineIndex = lines.findIndex((line) => line.id === state.activeLineId);

    if (line) {
      const visibleNodeIds = [...state.visibilityArray];
      const visibleNodes = visibleNodeIds.map((id) => {
        return lineData.nodes.find((node) => node.id === id) as Node<MachineGroupProps>;
      });

      const determineVisibleEdges = () => {
        const visibleEdgeArray: Edge[] = [];
        visibleNodeIds.forEach((nodeId) => {
          lineData.edges?.forEach((edge) => {
            if (nodeId === edge.target) {
              visibleEdgeArray.push(edge);
            }
          });
        });
        return visibleEdgeArray;
      };

      const updatedView: View = {
        viewId: line.views[viewIndex].viewId,
        viewTitle: state.viewTitle,
        nodes: visibleNodes,
        edges: determineVisibleEdges(),
        viewport: lineData.viewport,
      };

      lines[lineIndex].views[viewIndex] = updatedView;

      try {
        const response = await axios.post(`${environment.host_url}/api/line`, { line: lines[lineIndex] });
        if (response.status === 200) {
          return lines;
        }
      } catch (error) {
        console.error('Error saving view', error);
        thunkApi.abort();
        return;
      }
    }
    return;
  }
);

export const deleteView = createAsyncThunk('lines/deleteView', async (data, thunkApi) => {
  const { getState } = thunkApi;
  const rootState = getState() as RootState;
  const state = rootState.lines;

  const lines = _.cloneDeep(state.lines);
  const line = lines.find((line) => line.id === state.activeLineId);
  const lineIndex = lines.findIndex((line) => line.id === state.activeLineId);

  if (line) {
    const viewIndex = rootState.lines.activeViewIndex;
    lines[lineIndex].views.splice(viewIndex, 1);

    try {
      const response = await axios.post(`${environment.host_url}/api/line`, { line: lines[lineIndex] });
      if (response.status === 200) {
        return lines;
      }
    } catch (error) {
      console.error('Error deleting view', error);
      thunkApi.abort();
      return;
    }
  }
  return;
});
