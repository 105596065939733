import React, { FC } from 'react';
import { MachineGroupProps } from '@types';
import Buffer from '../Buffer/Buffer';
import Machine from '../Machine/Machine';
import Filter from '../Filter';
import styles from '../MachineGroups.module.css';
import { useDarkThemeMachineGroup } from '@hooks';

export const MachineGroupDoubleBuffer: FC<MachineGroupProps> = (props) => {
  return (
    <svg
      width="190"
      height="170"
      className={`${styles.group} group`}
      data-id={`group`}
      data-node-id={props.machineGroup.id}
      data-active-node={props.machineGroup.id ? true : false}
      data-test-id="MachineGroupDoubleBuffer"
    >
      <Filter />
      <path
        d="
          M 6, 1
          h 178
          q 5,0 5,5
          v 88
          q 0,5 -5,5
          h -80
          q -5,0 -5,5
          v 60
          q 0,5 -5,5
          h -88
          q -5,0 -5,-5
          v -158
          q 0,-5 5,-5
          Z
        "
        className={styles['group-outline']}
        style={useDarkThemeMachineGroup()}
      />
      <text x="12" y="10" className={styles['group-text']}>
        {props.machineGroup.displayName}
      </text>
      <Machine
        machine={props.machineGroup.components[0]}
        x={10}
        y={30}
        machineId={0}
        nodeId={`${props.machineGroup.id}`}
      />
      <Machine
        machine={props.machineGroup.components[1]}
        x={100}
        y={30}
        machineId={1}
        nodeId={`${props.machineGroup.id}`}
      />
      <Buffer x={10} y={99} buffer={props.machineGroup.buffers![0]} bufferId={0} nodeId={`${props.machineGroup.id}`} />
    </svg>
  );
};
