import React, { FC, useContext, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  Edge,
  MiniMap,
  Node,
  Panel,
  ReactFlowInstance,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { customEdgeTypes, customNodeTypes } from '@customNodes';
import { useActiveLine, useIsPlugin, useLineFetcher, useViewIndex } from '@hooks';
import styles from './ViewerWindow.module.css';
import { ThemeContext } from '@sharedContext';
import { Map } from '../Map';
import { PluginDataContext } from '@PluginDataWrapper';

const ViewerWindow: FC = () => {
  const isPlugin = useIsPlugin();
  const theme: any = useContext(ThemeContext);
  const panelData = useContext(PluginDataContext);

  const [showMinimap, setShowMinimap] = useState(false);
  const [showLineTitle, setShowLineTitle] = useState(true);
  const [allowZoom, setAllowZoom] = useState(false);

  const [reactFlowViewerInstance, setReactFlowViewerInstance] = useState<ReactFlowInstance>();
  const activeLine = useActiveLine();
  const activeViewIndex = useViewIndex();

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    if (activeLine.views.length > 0) {
      setNodes(activeLine.views[activeViewIndex].nodes || []);
      setEdges(activeLine.views[activeViewIndex].edges || []);
    }
  }, [activeLine, activeViewIndex]);

  useEffect(() => {
    if (reactFlowViewerInstance && activeLine.viewport) {
      reactFlowViewerInstance.setViewport(activeLine.viewport);
    }
  }, [reactFlowViewerInstance, activeLine.viewport]);

  useEffect(() => {
    if (reactFlowViewerInstance) {
      reactFlowViewerInstance.fitView({ padding: 0.2 });
    }
  });

  useEffect(() => {
    if (isPlugin) {
      setShowMinimap(panelData?.options?.miniMap !== undefined ? panelData.options.miniMap : showMinimap);
      setShowLineTitle(panelData?.options?.lineTitle !== undefined ? panelData.options.lineTitle : showLineTitle);
      setAllowZoom(panelData.options?.activateZoom !== undefined ? panelData.options.activateZoom : allowZoom);
    }
  }, [
    isPlugin,
    panelData?.options?.miniMap,
    showMinimap,
    panelData?.options?.lineTitle,
    showLineTitle,
    panelData.options?.activateZoom,
    allowZoom,
  ]);

  const onInit = (instance: ReactFlowInstance) => {
    setReactFlowViewerInstance(instance);
  };

  return (
    <div className={`${styles['viewer-main']}`}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        defaultEdgeOptions={{ type: 'custom' }}
        elementsSelectable={false}
        nodeTypes={customNodeTypes}
        edgeTypes={customEdgeTypes}
        onInit={onInit}
        snapToGrid={true}
        snapGrid={[5, 5]}
        minZoom={0.2}
        maxZoom={5}
        zoomOnScroll={allowZoom}
        proOptions={{ hideAttribution: true }}
        preventScrolling={false}
      >
        {showLineTitle && (
          <Panel
            id="line-display-name"
            data-testid="line-display-name"
            className={`${styles['react-flow__panel']}`}
            position="top-left"
            style={
              isPlugin
                ? theme.isDark
                  ? {
                      backgroundColor: theme.colors.background.secondary,
                      borderColor: theme.colors.border.medium,
                      boxShadow: theme.shadows.z2,
                    }
                  : {}
                : {}
            }
          >
            {activeLine?.displayName}
          </Panel>
        )}
        {showMinimap && (
          <MiniMap
            maskColor={isPlugin ? theme.colors.background.secondary : undefined}
            nodeColor={isPlugin ? theme.colors.action.hover : undefined}
            style={
              isPlugin
                ? theme.isDark
                  ? { opacity: 0.75, backgroundColor: theme.colors.background.secondary }
                  : { opacity: 0.75 }
                : { opacity: 0.75 }
            }
          />
        )}
        <Controls
          showInteractive={false}
          fitViewOptions={{ duration: 600, padding: 0.2 }}
          className={isPlugin ? (theme.isDark ? styles['controls-dark'] : '') : ''}
          showZoom={allowZoom}
        >
          {!isPlugin && (
            <ControlButton
              title="miniMap"
              onClick={() => {
                setShowMinimap((prev) => !prev);
              }}
            >
              <Map />
            </ControlButton>
          )}
        </Controls>
        <Background color={isPlugin ? (theme.isDark ? theme.colors.background.canvas : 'white') : 'white'} />
      </ReactFlow>
    </div>
  );
};

export default ViewerWindow;
