import { Line, View } from '@types';

export function setMachineMultiplier(line: Line): Line {
  line.nodes.forEach((node) => {
    node.data.machineGroup.components.forEach((component) => {
      component.multiplier = component.multiplier ?? 1;
    });
  });

  line.views.forEach((view) => {
    view.nodes.forEach((node) => {
      node.data.machineGroup.components.forEach((component) => {
        const mainNode = line.nodes.find((mNode) => mNode.data.machineGroup.id === node.data.machineGroup.id);
        if (mainNode) {
          const mainComponent = mainNode.data.machineGroup.components.find(
            (mComp) => mComp.enkName === component.enkName
          );
          if (mainComponent) {
            component.multiplier = mainComponent.multiplier ?? 1;
          }
        }
      });
    });
  });

  return line;
}

export function setNewViewId(views: View[]) {
  if (views.length > 0) {
    const lastView = views[views.length - 1];
    const newViewId = +lastView.viewId.split('-')[1] + 1;
    return newViewId;
  }
  return 0;
}
