import React, { FC } from 'react';
import { Handle, Position } from 'reactflow';
import { handleOffset, isHiddenLeft, positionFromTop } from './groupHandles-config';
import { useEditorIsActive } from '@hooks';
import styles from './GroupHandles.module.css';
import { GroupHandleProps } from '@types';

export const GroupHandlesLeft: FC<GroupHandleProps> = (props) => {
  const editorIsActive = useEditorIsActive();

  return (
    <>
      {/* // !OUTPUTS */}
      <Handle
        type="source"
        position={Position.Left}
        id={`output-left-top`}
        isConnectable={editorIsActive}
        style={
          editorIsActive
            ? { ...positionFromTop, top: positionFromTop.top - handleOffset }
            : { ...isHiddenLeft, top: isHiddenLeft.top - handleOffset }
        }
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="source"
        position={Position.Left}
        id={`output-left`}
        isConnectable={editorIsActive}
        style={editorIsActive ? positionFromTop : isHiddenLeft}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="source"
        position={Position.Left}
        id={`output-left-bottom`}
        isConnectable={editorIsActive}
        style={
          editorIsActive
            ? { ...positionFromTop, top: positionFromTop.top + handleOffset }
            : { ...isHiddenLeft, top: isHiddenLeft.top + handleOffset }
        }
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      {/* // !INPUTS */}
      <Handle
        type="target"
        position={Position.Left}
        id={`input-left-top`}
        isConnectable={editorIsActive}
        style={
          editorIsActive
            ? { ...positionFromTop, top: positionFromTop.top - handleOffset }
            : { ...isHiddenLeft, top: isHiddenLeft.top - handleOffset }
        }
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="target"
        position={Position.Left}
        id={`input-left`}
        isConnectable={editorIsActive}
        style={editorIsActive ? positionFromTop : isHiddenLeft}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="target"
        position={Position.Left}
        id={`input-left-bottom`}
        isConnectable={editorIsActive}
        style={
          editorIsActive
            ? { ...positionFromTop, top: positionFromTop.top + handleOffset }
            : { ...isHiddenLeft, top: isHiddenLeft.top + handleOffset }
        }
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />
    </>
  );
};
