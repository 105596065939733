import React, { useState } from 'react';

const ProductionWarning = () => {
  const [showWarning, setShowWarning] = useState(true);

  const styles = `
    .warning-container {
      max-width: 400px;
      width: 100%;
      text-align: center;
      padding: 20px;
      border: 1px solid black;
      border-radius: 8px;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
    }

    .warning-heading {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #ff6347;
    }

    .warning-message {
      font-size: 18px;
      color: #555;
      margin-bottom: 20px;
    }

    .warning-icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ff6347;
      color: #fff;
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  `;

  return (
    <div style={{ fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif' }}>
      <style>{styles}</style>
      {showWarning && (
        <div className="warning-container">
          <div className="warning-icon">X</div>
          <div className="warning-heading">Warning!</div>
          <div className="warning-message">There is no production at this moment!</div>
        </div>
      )}
    </div>
  );
};

export { ProductionWarning };
