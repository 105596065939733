import React, { FC } from 'react';
import { NodeResizer } from 'reactflow';

export const landmarkStyle = {
  width: 75,
  height: 75,
  background: 'white',
  border: '1px solid black',
  borderRadius: 15,
  fontFamily: 'var(--bde-typo-font-family)',
  fontWeight: 600,
  fontSize: 15,
  display: 'grid',
  alignContent: 'center',
  justifyContent: 'center',
};

const Landmark: FC<any> = (props) => {
  return (
    <>
      <NodeResizer color="#ff0071" isVisible={props.selected} minWidth={50} minHeight={50} />
      <div style={{ padding: 10 }} data-id={`landmark`} data-node-id={props.data.machineGroup.id}>
        {props.data.machineGroup.displayName}
      </div>
    </>
  );
};

export default Landmark;
