import React, { FC } from 'react';
import { MachineGroupProps } from '@types';
import Machine from '../Machine/Machine';
import Filter from '../Filter';
import styles from '../MachineGroups.module.css';
import { useDarkThemeMachineGroup } from '@hooks';

export const MachineGroupDoubleStacked: FC<MachineGroupProps> = (props) => {
  return (
    <svg
      width="100"
      height="170"
      className={`${styles.group} group`}
      data-id={`group`}
      data-node-id={props.machineGroup.id}
      data-active-node={props.machineGroup.id ? true : false}
      data-test-id="MachineGroupDoubleStacked"
    >
      <Filter />
      <rect
        x="1"
        y="1"
        width="98"
        height="168"
        rx="5"
        className={styles['group-outline']}
        style={useDarkThemeMachineGroup()}
      />
      <text x="12" y="10" className={styles['group-text']}>
        {props.machineGroup.displayName}
      </text>
      <Machine
        machine={props.machineGroup.components[0]}
        x={10}
        y={30}
        machineId={0}
        nodeId={`${props.machineGroup.id}`}
      />
      <Machine
        machine={props.machineGroup.components[1]}
        x={10}
        y={96}
        machineId={1}
        nodeId={`${props.machineGroup.id}`}
      />
    </svg>
  );
};
