import React, { FC } from 'react';
import { GroupHandleProps } from '@types';
import { Handle, Position } from 'reactflow';
import { useEditorIsActive } from '@hooks';
import styles from './GroupHandles.module.css';
import { handleOffset, isHiddenBottom, isHiddenBottomLeft, positionBottomLeft } from './groupHandles-config';

export const GroupHandlesBottom: FC<GroupHandleProps> = (props) => {
  const { groupWidth } = props;
  const editorIsActive = useEditorIsActive();

  const positionHandleLeft = {
    left: groupWidth / 2 - handleOffset,
    offset: -handleOffset,
  };

  const positionHandleMiddle = {
    left: groupWidth / 2,
    offset: 0,
  };

  const positionHandleRight = {
    left: groupWidth / 2 + handleOffset,
    offset: handleOffset,
  };

  const calculateBottomHandlePosition = (handlePosition: { left: number; offset: number }) => {
    const calculatedPositions = props.bottomHandleLeft
      ? editorIsActive
        ? { ...positionBottomLeft, left: positionBottomLeft.left + handlePosition.offset }
        : { ...isHiddenBottomLeft, left: isHiddenBottomLeft.left + handlePosition.offset }
      : editorIsActive
      ? handlePosition
      : { ...handlePosition, ...isHiddenBottom };
    return calculatedPositions;
  };

  return (
    <>
      {/* // !OUTPUTS */}
      <Handle
        type="source"
        position={Position.Bottom}
        id={`output-bottom-left`}
        isConnectable={editorIsActive}
        style={calculateBottomHandlePosition(positionHandleLeft)}
        className={`${editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`} ${
          styles['bottom-handle']
        }`}
      />

      <Handle
        type="source"
        position={Position.Bottom}
        id={`output-bottom`}
        isConnectable={editorIsActive}
        style={calculateBottomHandlePosition(positionHandleMiddle)}
        className={`${editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`} ${
          styles['bottom-handle']
        }`}
      />

      <Handle
        type="source"
        position={Position.Bottom}
        id={`output-bottom-right`}
        isConnectable={editorIsActive}
        style={calculateBottomHandlePosition(positionHandleRight)}
        className={`${editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`} ${
          styles['bottom-handle']
        }`}
      />

      {/* // !INPUTS */}
      <Handle
        type="target"
        position={Position.Bottom}
        id={`input-bottom-left`}
        isConnectable={editorIsActive}
        style={calculateBottomHandlePosition(positionHandleLeft)}
        className={`${editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`} ${
          styles['bottom-handle']
        }`}
      />

      <Handle
        type="target"
        position={Position.Bottom}
        id={`input-bottom`}
        isConnectable={editorIsActive}
        style={calculateBottomHandlePosition(positionHandleMiddle)}
        className={`${editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`} ${
          styles['bottom-handle']
        }`}
      />

      <Handle
        type="target"
        position={Position.Bottom}
        id={`input-bottom-right`}
        isConnectable={editorIsActive}
        style={calculateBottomHandlePosition(positionHandleRight)}
        className={`${editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`} ${
          styles['bottom-handle']
        }`}
      />
    </>
  );
};
