import React, { FC } from 'react';
import { NodeProps } from 'reactflow';
import { MachineGroupSingle } from '../MachineGroups/MachineGroupSingle';
import GroupHandles from '../../GroupHandles/GroupHandles';
import { CustomNodeToolbar } from '../../CustomNodeToolbar/CustomNodeToolbar';
import { useViewsEditor, useVisibilitySwitch } from '@hooks';

const MachineGroupSingleCN: FC<NodeProps> = (props) => {
  const { id, data } = props;
  const { groupIsVisible, updateVisibility } = useVisibilitySwitch(id);
  const viewsEditorActive = useViewsEditor();

  return (
    <>
      {viewsEditorActive && (
        <CustomNodeToolbar {...props} groupIsVisible={groupIsVisible} updateVisibility={updateVisibility} />
      )}
      <GroupHandles groupWidth={100} />
      <MachineGroupSingle machineGroup={data.machineGroup} />
    </>
  );
};

export default MachineGroupSingleCN;
