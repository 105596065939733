import React, { useState, useEffect } from 'react';
import { PanelData } from '@grafana/data';
import { findDataField } from '@findData';
import { machineStateOptions } from '../../../../libs/get-machine-state/src/lib/machinestate-options';
import { Line } from '@types';

interface StatusLegendProps {
  panelData: PanelData;
  line: Line | undefined;
}

export const StatusLegend: React.FC<StatusLegendProps> = ({ panelData, line }) => {
  const [currentStatuses, setCurrentStatuses] = useState<Array<{ name: string; color: string }>>([]);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (line && panelData && panelData.series.length > 0) {
      const uniqueStatuses = new Set<number>();
      panelData.series.forEach((series) => {
        const machineIds = findDataField(series, 'machineid');
        const values = findDataField(series, 'state_datasource_value');
        if (machineIds && values) {
          machineIds.values.forEach((id, index) => {
            if (id.includes(line.domain)) {
              uniqueStatuses.add(values.values[index]);
            }
          });
        }
      });

      const filteredStatuses = Array.from(uniqueStatuses)
        .map((status) => {
          const foundOption = machineStateOptions.find((option) => parseInt(option.path, 10) === status);
          return foundOption ? { name: foundOption.name, color: foundOption.defaultValue } : null;
        })
        .filter((status) => status !== null) as Array<{ name: string; color: string }>;

      setCurrentStatuses(filteredStatuses);
    }
  }, [panelData, line]);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        onClick={() => setIsVisible(!isVisible)}
        style={{
          cursor: 'pointer',
          color: 'black',
          background: 'rgba(128, 128, 128, 0.5)',
          padding: '5px 10px',
          borderRadius: '10px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          marginBottom: '5px',
          fontSize: '14px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '1px',
          transition: 'background 0.3s',
        }}
      >
        {isVisible ? 'Legende ausblenden' : 'Legende anzeigen'}
      </div>
      {isVisible && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          {currentStatuses.map((status, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }} data-testid="status-element">
              <span
                style={{
                  display: 'inline-block',
                  width: '20px',
                  height: '20px',
                  backgroundColor: status.color,
                  border: '1px solid black',
                }}
              ></span>
              <span>{status.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
