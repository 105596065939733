const top = 52;
const bottomLeft = 50;
const offset = 1;
const offsetBottom = 6;
export const handleOffset = 25;

export const positionFromTop = {
  top: top,
};

export const positionBottomLeft = {
  left: bottomLeft,
};

export const isHiddenLeft = {
  top: top,
  left: offset,
};

export const isHiddenRight = {
  top: top,
  right: offset,
};

export const isHiddenTop = {
  top: offset,
};

export const isHiddenBottom = {
  bottom: offset + offsetBottom,
};

export const isHiddenBottomLeft = {
  left: bottomLeft,
  bottom: offset + offsetBottom,
};
