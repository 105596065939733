import React, { FC, useContext, useEffect, useState } from 'react';
import { MachineProps, MachineStateColorStyle, STATE_OPTIONS } from '@types';
import { PluginDataContext } from '@PluginDataWrapper';
import { ThemeContext } from '@sharedContext';
import { Tooltip } from '@tooltip';
import { useEditorIsActive, useIsPlugin, useEnkMatch, useEnkNamesInDB, useMachineState } from '@hooks';
import styles from '../MachineGroups.module.css';
import { AlarmOutlined, WarningOutlined } from '@mui/icons-material';
import { constructMachineVariable, interpolateMachineVariable, sanitizeMachineId, setMachineStateColor } from '@utils';
import { Link } from 'react-router-dom';
import { getMachineStateLetterOption } from '@getMachineState';
import { useFormattedDuration } from '@grafanaHooks';
import { ConnectionStatus } from '@heartBeat';

const Machine: FC<MachineProps> = (props) => {
  const formattedDuration = useFormattedDuration(props.machine.enkName);
  const editorIsActive = useEditorIsActive();
  const enkMatchingIsActive = useEnkMatch();
  const enkNamesInDB = useEnkNamesInDB();
  const [referenceElement, setReferenceElement] = useState<SVGElement | null | undefined>(null);

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const hideTooltip = () => setTooltipIsVisible(false);
  const showTooltip = () => setTooltipIsVisible(true);

  const tooltipData = {
    displayName: constructMachineVariable(props.machine.enkName),
    enkName: props.machine.enkName,
  };

  const isPlugin = useIsPlugin();
  const theme: any = useContext(ThemeContext);
  const pluginPanelData = useContext(PluginDataContext);
  const showLetters = pluginPanelData.options?.showLetters;
  const isAndonBoard = pluginPanelData.options?.isAndonBoard;
  const showDuration = pluginPanelData.options?.showDuration;
  const machineState = useMachineState(props.machine.enkName);

  const [machineStateColorStyle, setMachineStateColorStyle] = useState<MachineStateColorStyle>({
    fill: `hsl(0, 0%, 69%, 0.8)`,
    stroke: `hsl(0, 0%, 69%, 1)`,
    strokeWidth: '3px',
  });

  const nIOStyles = {
    width: '21px',
    x: '50px',
    y: '-12px',
  };

  useEffect(() => {
    if (isPlugin) {
      const machineColor = setMachineStateColor(pluginPanelData, props, theme);
      setMachineStateColorStyle(machineColor);
    }
  }, [isPlugin, pluginPanelData, props, theme]);

  const machineStateLetterOption = getMachineStateLetterOption(machineState.state[STATE_OPTIONS.state]);

  const machine = (
    <>
      {!editorIsActive && tooltipIsVisible && <Tooltip referenceElement={referenceElement} data={tooltipData} />}
      <svg
        width="80"
        height="60"
        x={props.x}
        y={props.y}
        className={`${styles.machine} machine`}
        data-id={`machine-${props.machineId}`}
        data-machine-id={`${props.nodeId}-m-${props.machineId}`}
        ref={setReferenceElement}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
        data-testid={`machine-${props.machine.enkName}`}
      >
        <rect
          x="2"
          y="2"
          width="76"
          height="56"
          rx="15"
          className={styles['machine-rect']}
          style={machineStateColorStyle}
        />
        {pluginPanelData.panelData && isAndonBoard && (
          <ConnectionStatus
            machineId={props.machine.enkName}
            panelData={pluginPanelData.panelData}
            isAndonBoard={isAndonBoard}
          />
        )}
        {showDuration && (
          <text x="50%" y="35" className={`${styles['duration']} ${styles['machine-text']}`} textAnchor="middle">
            {formattedDuration}
          </text>
        )}
        <text x="13" y="12" className={styles['machine-text']} data-testid="machine-text">
          {props.machine.displayName}
        </text>
        {showLetters && (
          <text x="34" y="12" className={styles['machine-info-letter']} data-testid="machine-letter-state">
            {machineStateLetterOption &&
              isAndonBoard !== machineStateLetterOption.andonOnly &&
              machineStateLetterOption.letter}
          </text>
        )}
        {showLetters && isAndonBoard && (
          <text x="34" y="12" className={styles['machine-info-letter']} data-testid="machine-letter-state-andon">
            {machineStateLetterOption &&
              isAndonBoard === machineStateLetterOption.andonOnly &&
              machineStateLetterOption.letter}
          </text>
        )}
        {props.machine.enkName !== '' &&
          enkMatchingIsActive &&
          !enkNamesInDB.includes(sanitizeMachineId(props.machine.enkName)) && (
            <WarningOutlined width="21px" x="50px" y="-12px" color="warning" titleAccess="Not in DB" />
          )}
        {props.machine.isNIOCounter && props.machine.isNIOCounter !== props.machine.isIOCounter && (
          <AlarmOutlined
            width={nIOStyles.width}
            x={nIOStyles.x}
            y={nIOStyles.y}
            color="error"
            titleAccess="NIO"
            data-is-nio="true"
          />
        )}
        {props.machine.isIOCounter && props.machine.isNIOCounter !== props.machine.isIOCounter && (
          <AlarmOutlined
            width={nIOStyles.width}
            x={nIOStyles.x}
            y={nIOStyles.y}
            color="success"
            titleAccess="IO"
            data-is-io="true"
          />
        )}
        {props.machine.isNIOCounter &&
          props.machine.isIOCounter &&
          props.machine.isNIOCounter === props.machine.isIOCounter && (
            <AlarmOutlined
              width={nIOStyles.width}
              x={nIOStyles.x}
              y={nIOStyles.y}
              titleAccess="IO / NIO"
              data-is-io-nio="true"
            />
          )}
      </svg>
    </>
  );

  const machineLink = interpolateMachineVariable(pluginPanelData.options?.machineLink || '#', props.machine.enkName);
  if (isPlugin) {
    return <Link to={machineLink}>{machine}</Link>;
  }

  return machine;
};

export default Machine;
